import { __decorate } from 'tslib';
import * as i0 from '@angular/core';
import { Component, ChangeDetectionStrategy, Inject, ChangeDetectorRef, Optional, Self, ViewChild, Input, HostBinding, HostListener, Directive, NgModule } from '@angular/core';
import * as i7 from '@angular/forms';
import { NgControl } from '@angular/forms';
import { maskitoTimeOptionsGenerator } from '@maskito/kit';
import * as i2 from '@taiga-ui/cdk';
import { tuiCreateToken, tuiProvideOptions, TuiIdService, AbstractTuiNullableControl, ALWAYS_FALSE_HANDLER, tuiIsNativeFocused, TuiTime, TUI_STRICT_MATCHER, tuiIsElement, tuiIsInput, TUI_IS_MOBILE, TUI_IS_IOS, tuiAsFocusableItemAccessor, tuiAsControl, tuiPure } from '@taiga-ui/cdk';
import * as i1 from '@taiga-ui/core';
import { TUI_TEXTFIELD_HOST, TUI_TEXTFIELD_SIZE, tuiAsDataListHost, tuiAsOptionContent, TuiPrimitiveTextfieldComponent, AbstractTuiTextfieldHost, tuiAsTextfieldHost, TuiDataListModule, TuiWrapperModule, TuiHostedDropdownModule, TuiPrimitiveTextfieldModule, TuiTextfieldControllerModule, TuiSvgModule } from '@taiga-ui/core';
import { TUI_SELECT_OPTION, TuiSelectOptionModule } from '@taiga-ui/kit/components/select-option';
import { FIXED_DROPDOWN_CONTROLLER_PROVIDER } from '@taiga-ui/kit/providers';
import { TUI_TIME_TEXTS } from '@taiga-ui/kit/tokens';
import * as i8 from 'rxjs';
import { timer } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { MAX_TIME_VALUES } from '@taiga-ui/kit/constants';
import * as i5 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i3 from '@taiga-ui/kit/directives';
import { TuiValueAccessorModule } from '@taiga-ui/kit/directives';
import * as i4 from '@maskito/angular';
import { MaskitoModule } from '@maskito/angular';
import * as i6 from '@tinkoff/ng-polymorpheus';
import { PolymorpheusModule } from '@tinkoff/ng-polymorpheus';
const _c0 = ["tuiTime", ""];
function TuiNativeTimeComponent_datalist_0_option_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "option", 3);
  }
  if (rf & 2) {
    const item_r1 = ctx.$implicit;
    i0.ɵɵpropertyInterpolate("value", item_r1);
  }
}
function TuiNativeTimeComponent_datalist_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "datalist", 1);
    i0.ɵɵtemplate(1, TuiNativeTimeComponent_datalist_0_option_1_Template, 1, 1, "option", 2);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵproperty("id", ctx_r1.autoIdString);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngForOf", ctx_r1.items);
  }
}
const _c1 = ["*"];
const _c2 = a0 => ({
  $implicit: a0
});
function TuiInputTimeComponent_ng_template_5_button_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "button", 7);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const item_r2 = ctx.$implicit;
    const ctx_r2 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("disabled", ctx_r2.disabledItemHandler(item_r2))("value", item_r2);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", item_r2, " ");
  }
}
function TuiInputTimeComponent_ng_template_5_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "tui-data-list", 5);
    i0.ɵɵtemplate(1, TuiInputTimeComponent_ng_template_5_button_1_Template, 2, 3, "button", 6);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵproperty("size", ctx_r2.itemSize);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngForOf", ctx_r2.filtered);
  }
}
function TuiInputTimeComponent_ng_template_7_tui_svg_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "tui-svg", 10);
  }
  if (rf & 2) {
    const src_r4 = ctx.polymorpheusOutlet;
    i0.ɵɵproperty("src", src_r4);
  }
}
function TuiInputTimeComponent_ng_template_7_input_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "input", 11);
  }
}
function TuiInputTimeComponent_ng_template_7_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, TuiInputTimeComponent_ng_template_7_tui_svg_0_Template, 1, 1, "tui-svg", 8)(1, TuiInputTimeComponent_ng_template_7_input_1_Template, 1, 0, "input", 9);
  }
  if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵproperty("polymorpheusOutlet", ctx_r2.icon)("polymorpheusOutletContext", i0.ɵɵpureFunction1(3, _c2, ctx_r2.size));
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r2.showNativePicker);
  }
}
const TUI_INPUT_TIME_DEFAULT_OPTIONS = {
  icon: ({
    $implicit
  }) => $implicit === 's' ? 'tuiIconClock' : 'tuiIconClockLarge',
  mode: 'HH:MM',
  postfix: '',
  maxValues: MAX_TIME_VALUES,
  itemSize: 'm',
  nativePicker: false
};
/**
 * Default parameters for InputTime component
 */
const TUI_INPUT_TIME_OPTIONS = tuiCreateToken(TUI_INPUT_TIME_DEFAULT_OPTIONS);
function tuiInputTimeOptionsProvider(options) {
  return tuiProvideOptions(TUI_INPUT_TIME_OPTIONS, options, TUI_INPUT_TIME_DEFAULT_OPTIONS);
}
class TuiNativeTimeComponent {
  constructor(host, idService) {
    this.host = host;
    this.autoIdString = idService.generate();
  }
  get items() {
    return this.host.items.map(item => item.toString(this.host.mode));
  }
  get value() {
    return this.host.value.length === this.host.mode.length ? this.host.value : '';
  }
  get step() {
    switch (this.host.mode) {
      case 'HH:MM:SS':
        return 1;
      case 'HH:MM:SS.MSS':
        return 0.001;
      default:
        return 60;
    }
  }
  onChange(value) {
    this.host.onValueChange(value);
  }
}
TuiNativeTimeComponent.ɵfac = function TuiNativeTimeComponent_Factory(t) {
  return new (t || TuiNativeTimeComponent)(i0.ɵɵdirectiveInject(TUI_TEXTFIELD_HOST), i0.ɵɵdirectiveInject(TuiIdService));
};
TuiNativeTimeComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: TuiNativeTimeComponent,
  selectors: [["input", "tuiTime", ""]],
  hostAttrs: ["type", "time"],
  hostVars: 4,
  hostBindings: function TuiNativeTimeComponent_HostBindings(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵlistener("change.stop", function TuiNativeTimeComponent_change_stop_HostBindingHandler($event) {
        return ctx.onChange($event.target.value);
      })("click.stop.silent", function TuiNativeTimeComponent_click_stop_silent_HostBindingHandler() {
        return 0;
      })("mousedown.stop.silent", function TuiNativeTimeComponent_mousedown_stop_silent_HostBindingHandler() {
        return 0;
      });
    }
    if (rf & 2) {
      i0.ɵɵhostProperty("tabIndex", -1)("value", ctx.value)("step", ctx.step);
      i0.ɵɵattribute("list", ctx.autoIdString);
    }
  },
  attrs: _c0,
  decls: 1,
  vars: 1,
  consts: [[3, "id", 4, "ngIf"], [3, "id"], [3, "value", 4, "ngFor", "ngForOf"], [3, "value"]],
  template: function TuiNativeTimeComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵtemplate(0, TuiNativeTimeComponent_datalist_0_Template, 2, 2, "datalist", 0);
    }
    if (rf & 2) {
      i0.ɵɵproperty("ngIf", ctx.items.length);
    }
  },
  dependencies: [i5.NgIf, i5.NgForOf],
  styles: ["[_nghost-%COMP%]{position:absolute;top:0;left:0;width:100%;height:100%;opacity:0;pointer-events:auto;font-size:2rem}"],
  changeDetection: 0
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiNativeTimeComponent, [{
    type: Component,
    args: [{
      selector: 'input[tuiTime]',
      template: `
        <datalist
            *ngIf="items.length"
            [id]="autoIdString"
        >
            <option
                *ngFor="let item of items"
                value="{{ item }}"
            ></option>
        </datalist>
    `,
      host: {
        type: 'time',
        '[attr.list]': 'autoIdString',
        '[tabIndex]': '-1',
        '[value]': 'value',
        '[step]': 'step',
        '(change.stop)': 'onChange($event.target.value)',
        '(click.stop.silent)': '0',
        '(mousedown.stop.silent)': '0'
      },
      styleUrls: ['./native-time.style.less'],
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], function () {
    return [{
      type: undefined,
      decorators: [{
        type: Inject,
        args: [TUI_TEXTFIELD_HOST]
      }]
    }, {
      type: i2.TuiIdService,
      decorators: [{
        type: Inject,
        args: [TuiIdService]
      }]
    }];
  }, null);
})();
class TuiInputTimeComponent extends AbstractTuiNullableControl {
  constructor(control, cdr, timeTexts$, options, isMobile, isIos, textfieldSize) {
    super(control, cdr);
    this.timeTexts$ = timeTexts$;
    this.options = options;
    this.isMobile = isMobile;
    this.isIos = isIos;
    this.textfieldSize = textfieldSize;
    this.disabledItemHandler = ALWAYS_FALSE_HANDLER;
    this.items = [];
    this.itemSize = this.options.itemSize;
    this.strict = false;
    this.mode = this.options.mode;
    /** @deprecated use `tuiTextfieldPostfix` from {@link TuiTextfieldControllerModule} instead */
    this.postfix = this.options.postfix;
    this.open = false;
  }
  get size() {
    return this.textfieldSize.size;
  }
  get nativeFocusableElement() {
    var _a, _b;
    return (_b = (_a = this.textfield) === null || _a === void 0 ? void 0 : _a.nativeFocusableElement) !== null && _b !== void 0 ? _b : null;
  }
  get focused() {
    return tuiIsNativeFocused(this.nativeFocusableElement);
  }
  get canOpen() {
    return this.interactive && !!this.filtered.length;
  }
  get filtered() {
    return this.filter(this.items, this.mode, this.computedSearch);
  }
  get showNativePicker() {
    return this.nativePicker && (!this.isIos || this.mode === 'HH:MM' && !this.items.length);
  }
  get nativeDatalist() {
    return this.nativePicker && !this.isIos;
  }
  get maskOptions() {
    return this.calculateMask(this.mode);
  }
  get computedValue() {
    return this.value ? this.value.toString(this.mode) : this.nativeValue;
  }
  get computedSearch() {
    return this.computedValue.length !== this.mode.length ? this.computedValue : '';
  }
  get innerPseudoFocused() {
    if (this.pseudoFocus === false) {
      return false;
    }
    if (this.open && this.canOpen || this.computedFocused) {
      return true;
    }
    return null;
  }
  get icon() {
    return this.options.icon;
  }
  get nativeValue() {
    var _a;
    return ((_a = this.nativeFocusableElement) === null || _a === void 0 ? void 0 : _a.value) || '';
  }
  set nativeValue(value) {
    if (!this.nativeFocusableElement) {
      return;
    }
    this.nativeFocusableElement.value = value;
  }
  getFiller$(mode) {
    return this.timeTexts$.pipe(map(texts => texts[mode]));
  }
  onClick() {
    this.open = !this.open;
  }
  onValueChange(value) {
    this.open = !!this.items.length;
    if (this.control) {
      this.control.updateValueAndValidity({
        emitEvent: false
      });
    }
    const match = this.getMatch(value);
    if (match !== undefined) {
      this.value = match;
      return;
    }
    if (value.length !== this.mode.length) {
      this.value = null;
      return;
    }
    const time = TuiTime.fromString(value);
    this.value = this.strict ? this.findNearestTimeFromItems(time) : time;
  }
  onFocused(focused) {
    this.updateFocused(focused);
    if (focused || this.value !== null || this.nativeValue === '' || this.mode === 'HH:MM') {
      return;
    }
    this.value = TuiTime.fromString(this.nativeValue);
    timer(0).pipe(takeUntil(this.destroy$)).subscribe(() => {
      if (this.nativeValue.endsWith('.') || this.nativeValue.endsWith(':')) {
        this.nativeValue = this.nativeValue.slice(0, -1);
      }
    });
  }
  onArrowUp(event) {
    if (this.items.length) {
      return;
    }
    this.processArrow(event, 1);
  }
  onArrowDown(event) {
    if (this.items.length) {
      return;
    }
    this.processArrow(event, -1);
  }
  handleOption(item) {
    this.focusInput();
    this.value = item;
  }
  onOpen(open) {
    this.open = open;
  }
  writeValue(value) {
    super.writeValue(value);
    this.nativeValue = value ? this.computedValue : '';
  }
  get nativePicker() {
    return !!this.options.nativePicker && this.isMobile;
  }
  calculateMask(mode) {
    const {
      HH,
      MM,
      SS,
      MS
    } = this.options.maxValues;
    return maskitoTimeOptionsGenerator({
      mode,
      timeSegmentMaxValues: {
        hours: HH,
        minutes: MM,
        seconds: SS,
        milliseconds: MS
      }
    });
  }
  filter(items, mode, search) {
    return items.filter(item => item.toString(mode).includes(search));
  }
  findNearestTimeFromItems(value) {
    return this.items.reduce((previous, current) => Math.abs(current.valueOf() - value.valueOf()) < Math.abs(previous.valueOf() - value.valueOf()) ? current : previous, new TuiTime(0, 0));
  }
  getMatch(value) {
    return this.items.find(item => TUI_STRICT_MATCHER(item, value));
  }
  close() {
    this.open = false;
  }
  processArrow(event, shift) {
    const {
      target
    } = event;
    if (this.readOnly || !tuiIsElement(target) || !tuiIsInput(target)) {
      return;
    }
    const selectionStart = target.selectionStart || 0;
    this.shiftTime(this.calculateShift(selectionStart, shift));
    target.setSelectionRange(selectionStart, selectionStart);
    event.preventDefault();
  }
  calculateShift(selectionStart, shift) {
    if (selectionStart <= 2) {
      return {
        hours: shift
      };
    }
    if (selectionStart <= 5) {
      return {
        minutes: shift
      };
    }
    if (selectionStart <= 8) {
      return {
        seconds: shift
      };
    }
    return {
      ms: shift
    };
  }
  shiftTime(shift) {
    if (this.value === null) {
      return;
    }
    const increasedTime = this.value.shift(shift);
    // Manual update so we can set caret position properly
    this.nativeValue = increasedTime.toString(this.mode);
    this.value = increasedTime;
  }
  focusInput(preventScroll = false) {
    if (this.nativeFocusableElement) {
      this.nativeFocusableElement.focus({
        preventScroll
      });
      this.close();
    }
  }
}
TuiInputTimeComponent.ɵfac = function TuiInputTimeComponent_Factory(t) {
  return new (t || TuiInputTimeComponent)(i0.ɵɵdirectiveInject(NgControl, 10), i0.ɵɵdirectiveInject(ChangeDetectorRef), i0.ɵɵdirectiveInject(TUI_TIME_TEXTS), i0.ɵɵdirectiveInject(TUI_INPUT_TIME_OPTIONS), i0.ɵɵdirectiveInject(TUI_IS_MOBILE), i0.ɵɵdirectiveInject(TUI_IS_IOS), i0.ɵɵdirectiveInject(TUI_TEXTFIELD_SIZE));
};
TuiInputTimeComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: TuiInputTimeComponent,
  selectors: [["tui-input-time"]],
  viewQuery: function TuiInputTimeComponent_Query(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵviewQuery(TuiPrimitiveTextfieldComponent, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.textfield = _t.first);
    }
  },
  hostVars: 1,
  hostBindings: function TuiInputTimeComponent_HostBindings(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵlistener("click", function TuiInputTimeComponent_click_HostBindingHandler() {
        return ctx.onClick();
      });
    }
    if (rf & 2) {
      i0.ɵɵattribute("data-size", ctx.size);
    }
  },
  inputs: {
    disabledItemHandler: "disabledItemHandler",
    items: "items",
    itemSize: "itemSize",
    strict: "strict",
    mode: "mode",
    postfix: "postfix"
  },
  features: [i0.ɵɵProvidersFeature([tuiAsFocusableItemAccessor(TuiInputTimeComponent), tuiAsControl(TuiInputTimeComponent), tuiAsDataListHost(TuiInputTimeComponent), tuiAsOptionContent(TUI_SELECT_OPTION)], [FIXED_DROPDOWN_CONTROLLER_PROVIDER]), i0.ɵɵInheritDefinitionFeature],
  ngContentSelectors: _c1,
  decls: 9,
  vars: 18,
  consts: [["dropdownContent", ""], ["iconContent", ""], [1, "t-wrapper", 3, "focusedChange", "openChange", "canOpen", "content", "open"], ["tuiValueAccessor", "", 1, "t-textfield", 3, "keydown.arrowDown", "keydown.arrowUp", "valueChange", "disabled", "focusable", "invalid", "maskito", "nativeId", "postfix", "pseudoActive", "pseudoFocus", "pseudoHover", "readOnly", "tuiTextfieldFiller", "tuiTextfieldIcon", "value"], ["inputmode", "numeric", "tuiTextfield", ""], ["automation-id", "tui-input-time__dropdown", 3, "size"], ["automation-id", "tui-input-time__item", "tuiOption", "", 3, "disabled", "value", 4, "ngFor", "ngForOf"], ["automation-id", "tui-input-time__item", "tuiOption", "", 3, "disabled", "value"], ["appearance", "icon", "tuiWrapper", "", 3, "src", 4, "polymorpheusOutlet", "polymorpheusOutletContext"], ["tuiTime", "", 4, "ngIf"], ["appearance", "icon", "tuiWrapper", "", 3, "src"], ["tuiTime", ""]],
  template: function TuiInputTimeComponent_Template(rf, ctx) {
    if (rf & 1) {
      const _r1 = i0.ɵɵgetCurrentView();
      i0.ɵɵprojectionDef();
      i0.ɵɵelementStart(0, "tui-hosted-dropdown", 2);
      i0.ɵɵlistener("focusedChange", function TuiInputTimeComponent_Template_tui_hosted_dropdown_focusedChange_0_listener($event) {
        i0.ɵɵrestoreView(_r1);
        return i0.ɵɵresetView(ctx.onFocused($event));
      })("openChange", function TuiInputTimeComponent_Template_tui_hosted_dropdown_openChange_0_listener($event) {
        i0.ɵɵrestoreView(_r1);
        return i0.ɵɵresetView(ctx.onOpen($event));
      });
      i0.ɵɵelementStart(1, "tui-primitive-textfield", 3);
      i0.ɵɵpipe(2, "async");
      i0.ɵɵlistener("keydown.arrowDown", function TuiInputTimeComponent_Template_tui_primitive_textfield_keydown_arrowDown_1_listener($event) {
        i0.ɵɵrestoreView(_r1);
        return i0.ɵɵresetView(ctx.onArrowDown($event));
      })("keydown.arrowUp", function TuiInputTimeComponent_Template_tui_primitive_textfield_keydown_arrowUp_1_listener($event) {
        i0.ɵɵrestoreView(_r1);
        return i0.ɵɵresetView(ctx.onArrowUp($event));
      })("valueChange", function TuiInputTimeComponent_Template_tui_primitive_textfield_valueChange_1_listener($event) {
        i0.ɵɵrestoreView(_r1);
        return i0.ɵɵresetView(ctx.onValueChange($event));
      });
      i0.ɵɵprojection(3);
      i0.ɵɵelement(4, "input", 4);
      i0.ɵɵelementEnd()();
      i0.ɵɵtemplate(5, TuiInputTimeComponent_ng_template_5_Template, 2, 2, "ng-template", null, 0, i0.ɵɵtemplateRefExtractor)(7, TuiInputTimeComponent_ng_template_7_Template, 2, 5, "ng-template", null, 1, i0.ɵɵtemplateRefExtractor);
    }
    if (rf & 2) {
      const dropdownContent_r5 = i0.ɵɵreference(6);
      const iconContent_r6 = i0.ɵɵreference(8);
      i0.ɵɵproperty("canOpen", ctx.canOpen && !ctx.nativeDatalist)("content", dropdownContent_r5)("open", ctx.canOpen && ctx.open);
      i0.ɵɵadvance();
      i0.ɵɵproperty("disabled", ctx.disabled)("focusable", ctx.focusable)("invalid", ctx.computedInvalid)("maskito", ctx.maskOptions)("nativeId", ctx.nativeId)("postfix", ctx.postfix)("pseudoActive", ctx.pseudoActive)("pseudoFocus", ctx.innerPseudoFocused)("pseudoHover", ctx.pseudoHover)("readOnly", ctx.readOnly)("tuiTextfieldFiller", i0.ɵɵpipeBind1(2, 16, ctx.getFiller$(ctx.mode)) || "")("tuiTextfieldIcon", iconContent_r6)("value", ctx.computedValue);
    }
  },
  dependencies: [i1.TuiHostedDropdownComponent, i1.TuiPrimitiveTextfieldComponent, i1.TuiTextfieldComponent, i1.TuiDataListComponent, i1.TuiOptionComponent, i1.TuiSvgComponent, TuiNativeTimeComponent, i1.TuiPrimitiveTextfieldDirective, i3.TuiValueAccessorDirective, i4.MaskitoDirective, i1.TuiTextfieldFillerDirective, i1.TuiTextfieldIconDirective, i5.NgForOf, i6.PolymorpheusOutletDirective, i1.TuiWrapperDirective, i5.NgIf, i5.AsyncPipe],
  styles: ["[_nghost-%COMP%]{display:block;border-radius:var(--tui-radius-m);text-align:left}._disabled[_nghost-%COMP%]{pointer-events:none}.t-wrapper[_ngcontent-%COMP%]{display:block;border-radius:inherit}.t-textfield[_ngcontent-%COMP%]{border-radius:inherit;text-align:inherit}"],
  changeDetection: 0
});
__decorate([tuiPure], TuiInputTimeComponent.prototype, "getFiller$", null);
__decorate([tuiPure], TuiInputTimeComponent.prototype, "calculateMask", null);
__decorate([tuiPure], TuiInputTimeComponent.prototype, "filter", null);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiInputTimeComponent, [{
    type: Component,
    args: [{
      selector: 'tui-input-time',
      templateUrl: './input-time.template.html',
      styleUrls: ['./input-time.style.less'],
      changeDetection: ChangeDetectionStrategy.OnPush,
      providers: [tuiAsFocusableItemAccessor(TuiInputTimeComponent), tuiAsControl(TuiInputTimeComponent), tuiAsDataListHost(TuiInputTimeComponent), tuiAsOptionContent(TUI_SELECT_OPTION)],
      viewProviders: [FIXED_DROPDOWN_CONTROLLER_PROVIDER]
    }]
  }], function () {
    return [{
      type: i7.NgControl,
      decorators: [{
        type: Optional
      }, {
        type: Self
      }, {
        type: Inject,
        args: [NgControl]
      }]
    }, {
      type: i0.ChangeDetectorRef,
      decorators: [{
        type: Inject,
        args: [ChangeDetectorRef]
      }]
    }, {
      type: i8.Observable,
      decorators: [{
        type: Inject,
        args: [TUI_TIME_TEXTS]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [TUI_INPUT_TIME_OPTIONS]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [TUI_IS_MOBILE]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [TUI_IS_IOS]
      }]
    }, {
      type: i1.TuiTextfieldSizeDirective,
      decorators: [{
        type: Inject,
        args: [TUI_TEXTFIELD_SIZE]
      }]
    }];
  }, {
    textfield: [{
      type: ViewChild,
      args: [TuiPrimitiveTextfieldComponent]
    }],
    disabledItemHandler: [{
      type: Input
    }],
    items: [{
      type: Input
    }],
    itemSize: [{
      type: Input
    }],
    strict: [{
      type: Input
    }],
    mode: [{
      type: Input
    }],
    postfix: [{
      type: Input
    }],
    size: [{
      type: HostBinding,
      args: ['attr.data-size']
    }],
    getFiller$: [],
    onClick: [{
      type: HostListener,
      args: ['click']
    }],
    calculateMask: [],
    filter: []
  });
})();
class TuiInputTimeDirective extends AbstractTuiTextfieldHost {
  get value() {
    return this.host.computedValue;
  }
  get mode() {
    return this.host.mode;
  }
  get items() {
    return this.host.items;
  }
  onValueChange(value) {
    if (!value) {
      this.host.nativeValue = '';
    }
    this.host.onValueChange(value);
  }
  process(input) {
    input.inputMode = 'numeric';
  }
}
TuiInputTimeDirective.ɵfac = /* @__PURE__ */(() => {
  let ɵTuiInputTimeDirective_BaseFactory;
  return function TuiInputTimeDirective_Factory(t) {
    return (ɵTuiInputTimeDirective_BaseFactory || (ɵTuiInputTimeDirective_BaseFactory = i0.ɵɵgetInheritedFactory(TuiInputTimeDirective)))(t || TuiInputTimeDirective);
  };
})();
TuiInputTimeDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: TuiInputTimeDirective,
  selectors: [["tui-input-time"]],
  features: [i0.ɵɵProvidersFeature([tuiAsTextfieldHost(TuiInputTimeDirective)]), i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiInputTimeDirective, [{
    type: Directive,
    args: [{
      selector: 'tui-input-time',
      providers: [tuiAsTextfieldHost(TuiInputTimeDirective)]
    }]
  }], null, null);
})();
class TuiInputTimeModule {}
TuiInputTimeModule.ɵfac = function TuiInputTimeModule_Factory(t) {
  return new (t || TuiInputTimeModule)();
};
TuiInputTimeModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: TuiInputTimeModule
});
TuiInputTimeModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule, MaskitoModule, TuiSelectOptionModule, TuiDataListModule, TuiWrapperModule, TuiHostedDropdownModule, TuiPrimitiveTextfieldModule, TuiValueAccessorModule, TuiTextfieldControllerModule, TuiSvgModule, PolymorpheusModule]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiInputTimeModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, MaskitoModule, TuiSelectOptionModule, TuiDataListModule, TuiWrapperModule, TuiHostedDropdownModule, TuiPrimitiveTextfieldModule, TuiValueAccessorModule, TuiTextfieldControllerModule, TuiSvgModule, PolymorpheusModule],
      declarations: [TuiInputTimeComponent, TuiNativeTimeComponent, TuiInputTimeDirective],
      exports: [TuiInputTimeComponent, TuiInputTimeDirective]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TUI_INPUT_TIME_DEFAULT_OPTIONS, TUI_INPUT_TIME_OPTIONS, TuiInputTimeComponent, TuiInputTimeDirective, TuiInputTimeModule, tuiInputTimeOptionsProvider };
