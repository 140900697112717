<div class="knk-job-scheduler">
  <tui-radio-labeled
    [(ngModel)]="selectedRecountOption"
    *ngFor="let option of filteredOptions"
    size="l"
    (ngModelChange)="changeSelectedRecount($event)"
    [item]="option.value"
    [pseudoDisabled]="onlyPlain && option.value !== RecountOption.PlainCron"
  >
    <div>{{ option.label | translate }}</div>
    <div class="description" *ngIf="option.description">
      {{ option.description | translate }}
    </div>
  </tui-radio-labeled>

  <div class="knk-job-scheduler__build-container" *ngIf="selectedRecountOption">
    <knk-cron-builder
      (cronChanged)="updateCronFromCronBuilder($event)"
      *ngIf="selectedRecountOption === RecountOption.BuildCron"
    ></knk-cron-builder>

    <div *ngIf="selectedRecountOption === RecountOption.PlainCron">
      <tui-input [formControl]="cronControl" [tuiTextfieldCleaner]="true" [tuiTextfieldLabelOutside]="true">
        {{ 'jobScheduler.cronInputLabel' | translate }}
        <input placeholder="0 0 21 ? * FRI *" tuiTextfield type="text" />
      </tui-input>

      <tui-notification *ngIf="cronControl.dirty && cronControl.hasError('pattern')" status="error" class="info-box">
        {{ 'jobScheduler.invalidCron' | translate }}
      </tui-notification>

      <tui-notification status="warning" class="info-box">
        {{ 'jobScheduler.plainCronUtcWarning' | translate }}
      </tui-notification>

      <tui-notification
        *ngIf="
          showPlainCronDescription &&
          cronControl.value &&
          selectedRecountOption === RecountOption.PlainCron &&
          !cronControl.hasError('pattern')
        "
        status="warning"
        class="info-box"
      >
        {{ 'editSegmentSchedule.cronDescriptionHint' | translate: { desc: getCronDescription() } }}
      </tui-notification>
    </div>

    <tui-input-date-time
      *ngIf="selectedRecountOption === RecountOption.Once"
      [(ngModel)]="specificTime"
      (ngModelChange)="updateCronFromDate($event)"
    >
      {{ 'DateTime' | translate }}
      <input placeholder="OCT 26 1985 01:22" tuiTextfield />
    </tui-input-date-time>
  </div>
</div>
