<div class="knk-cron-builder">
  <div class="knk-cron-builder__every-builder">
    <span>{{ 'cronBuilder.every' | translate }}</span>

    <tui-hosted-dropdown
      tuiDropdownAlign="right"
      [content]="dropdown"
      [(open)]="isEveryDropdownOpen"
    >
      <a tabindex="0" tuiLink class="link">
        {{ getIntervalLabel(interval) | translate }}
        <tui-svg
          src="tuiIconChevronDown"
          class="arrow"
          [class.arrow_open]="isEveryDropdownOpen"
        ></tui-svg>
      </a>
    </tui-hosted-dropdown>

    <ng-template #dropdown>
      <tui-data-list>
        <button
          *ngFor="let item of everyOptions"
          tuiOption
          class="item"
          (click)="updateEveryInterval(item.value)"
        >
          {{ item.label | translate }}
          <tui-svg *ngIf="interval === item.value" src="tuiIconCheck"></tui-svg>
        </button>
      </tui-data-list>
    </ng-template>

    <tui-hosted-dropdown
      tuiDropdownAlign="right"
      [content]="startTimeDropdown"
      [tuiActiveZoneParent]="activeZone"
      [(open)]="isStartTimeDropdownOpen"
    >
      в
      <a tabindex="0" tuiLink class="link">
        {{ formattedStartTime }}
        <tui-svg
          src="tuiIconChevronDown"
          class="arrow"
          [class.arrow_open]="isStartTimeDropdownOpen"
        ></tui-svg>
      </a>
    </tui-hosted-dropdown>

    <ng-template #startTimeDropdown>
      <div style="width: 100px">
        <tui-input-time
          [ngModel]="startTime"
          (ngModelChange)="updateStartTime($event)"
          [tuiTextfieldLabelOutside]="true"
        >
        </tui-input-time>
      </div>
    </ng-template>
  </div>

  <ng-container *ngIf="interval === RepeatInterval.EveryWeek">
    <div class="knk-cron-builder__bullets">
      <div
        class="knk-cron-builder__bullets__bullet"
        [ngClass]="{ active: isDaySelected(day.value) }"
        *ngFor="let day of daysOptions; let last = last"
        (click)="toggleDay(day.value)"
      >
        {{ day.label | translate }}
      </div>
    </div>
  </ng-container>
</div>
