import { Component, EventEmitter, inject, OnInit, Output } from '@angular/core';
import { Cron } from '@konnektu/helpers';
import { TuiActiveZoneDirective, TuiTime } from '@taiga-ui/cdk';

export enum RepeatInterval {
  EveryDay,
  EveryWeek,
  EveryMonth,
  EveryYear
}

export enum WeekDay {
  Monday = 1,
  Tuesday,
  Wednesday,
  Thursday,
  Friday,
  Saturday,
  Sunday
}

@Component({
  selector: 'knk-cron-builder',
  templateUrl: 'cron-builder.template.html',
  styleUrls: ['cron-builder.styles.scss']
})
export class CronBuilderComponent implements OnInit {
  protected readonly activeZone = inject(TuiActiveZoneDirective, {
    optional: true,
    self: true
  });

  isEveryDropdownOpen = false;

  interval = RepeatInterval.EveryDay;

  isStartTimeDropdownOpen = false;

  startTime: TuiTime | null = new TuiTime(12, 0);

  cron: Cron = new Cron();

  RepeatInterval = RepeatInterval;

  everyOptions = [
    { value: RepeatInterval.EveryDay, label: 'cronBuilder.dateInterval.day' },
    { value: RepeatInterval.EveryWeek, label: 'cronBuilder.dateInterval.week' }
  ];

  daysOptions = [
    { label: 'cronBuilder.dayShort.monday', value: WeekDay.Monday },
    { label: 'cronBuilder.dayShort.tuesday', value: WeekDay.Tuesday },
    { label: 'cronBuilder.dayShort.wednesday', value: WeekDay.Wednesday },
    { label: 'cronBuilder.dayShort.thursday', value: WeekDay.Thursday },
    { label: 'cronBuilder.dayShort.friday', value: WeekDay.Friday },
    { label: 'cronBuilder.dayShort.saturday', value: WeekDay.Saturday },
    { label: 'cronBuilder.dayShort.sunday', value: WeekDay.Sunday }
  ];

  selectedDays: Set<WeekDay> = new Set([WeekDay.Monday]);

  @Output() cronChanged = new EventEmitter();

  get formattedStartTime() {
    return this.startTime?.toString('HH:MM');
  }

  ngOnInit() {
    this.updateCron();
  }

  getIntervalLabel(interval: RepeatInterval) {
    return this.everyOptions.find((o) => o.value === interval)?.label ?? '';
  }

  isDaySelected(day: WeekDay) {
    return this.selectedDays.has(day);
  }

  updateEveryInterval(repeatInterval: RepeatInterval): void {
    this.isEveryDropdownOpen = false;
    this.interval = repeatInterval;
    this.updateCron();
  }

  toggleDay(day: WeekDay): void {
    if (this.isDaySelected(day)) {
      if (this.selectedDays.size === 1) {
        return;
      }
      this.selectedDays.delete(day);
    } else {
      this.selectedDays.add(day);
    }
  }

  updateCron() {
    const cron = new Cron();

    const date = new Date();

    if (this.startTime) {
      date.setMinutes(this.startTime.minutes);
      date.setHours(this.startTime.hours);
    }

    cron.setMinutes(date.getUTCMinutes());
    cron.setHours(date.getUTCHours());
    if (this.interval === RepeatInterval.EveryDay) {
      cron.setMonth('*');
      cron.setDayOfWeek('?');
      cron.setYear('*');
      cron.setSeconds(0);
      cron.setDayOfMonth('*');
    }
    if (this.interval === RepeatInterval.EveryWeek) {
      cron.setDayOfMonth('?');
      cron.setMonth('*');
      cron.setDayOfWeek(
        [...this.selectedDays.values()]
          .map((selectedDay) => this.selectedDayEnumToString(selectedDay))
          .join(',')
      );
      cron.setYear('*');
      cron.setSeconds(0);
    }

    this.cron = cron;
    this.cronChanged.emit(this.cron);
  }

  updateStartTime(time: TuiTime) {
    this.startTime = time;
    this.updateCron();
  }

  private selectedDayEnumToString(day: WeekDay): string {
    switch (day) {
      case WeekDay.Monday:
        return 'MON';
      case WeekDay.Tuesday:
        return 'TUE';
      case WeekDay.Wednesday:
        return 'WED';
      case WeekDay.Thursday:
        return 'THU';
      case WeekDay.Friday:
        return 'FRI';
      case WeekDay.Saturday:
        return 'SAT';
      case WeekDay.Sunday:
        return 'SUN';
    }
  }
}
