import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  inject
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Cron, DestroyService } from '@konnektu/helpers';
import { TranslateService } from '@ngx-translate/core';
import { TuiDay, TuiTime } from '@taiga-ui/cdk';
import { takeUntil } from 'rxjs';

export enum RecountOption {
  PlainCron = 1,
  BuildCron,
  Once,
  DoNotRecount
}

const LOCALIZED_OPTIONS: Record<
  'segments' | 'communications' | 'jobs' | 'default',
  { label: string; value: RecountOption; description?: string }[]
> = {
  communications: [
    {
      label: 'jobScheduler.communicationsLabels.recountWithCron',
      value: RecountOption.PlainCron,
      description: 'jobScheduler.plainCronDescription'
    },
    {
      label: 'jobScheduler.communicationsLabels.withRepeat',
      value: RecountOption.BuildCron,
      description: 'jobScheduler.buildCronDescription'
    },
    {
      label: 'jobScheduler.communicationsLabels.once',
      value: RecountOption.Once,
      description: 'jobScheduler.repeatOnceDescription'
    },
    {
      label: 'jobScheduler.communicationsLabels.doNotRecount',
      value: RecountOption.DoNotRecount
    }
  ],
  jobs: [
    {
      label: 'jobScheduler.jobsLabels.recountWithCron',
      value: RecountOption.PlainCron,
      description: 'jobScheduler.plainCronDescription'
    },
    {
      label: 'jobScheduler.jobsLabels.withRepeat',
      value: RecountOption.BuildCron,
      description: 'jobScheduler.buildCronDescription'
    },
    {
      label: 'jobScheduler.jobsLabels.once',
      description: 'jobScheduler.repeatOnceDescription',
      value: RecountOption.Once
    },
    {
      label: 'jobScheduler.jobsLabels.doNotRecount',
      value: RecountOption.DoNotRecount
    }
  ],
  segments: [
    {
      label: 'jobScheduler.segmentsLabels.recountWithCron',
      description: 'jobScheduler.plainCronDescription',
      value: RecountOption.PlainCron
    },
    {
      label: 'jobScheduler.segmentsLabels.withRepeat',
      description: 'jobScheduler.buildCronDescription',
      value: RecountOption.BuildCron
    },
    {
      label: 'jobScheduler.segmentsLabels.once',
      description: 'jobScheduler.repeatOnceDescription',
      value: RecountOption.Once
    },
    {
      label: 'jobScheduler.segmentsLabels.doNotRecount',
      value: RecountOption.DoNotRecount
    }
  ],
  default: [
    {
      label: 'jobScheduler.recountWithCron',
      description: 'jobScheduler.plainCronDescription',
      value: RecountOption.PlainCron
    },
    {
      label: 'jobScheduler.withRepeat',
      description: 'jobScheduler.buildCronDescription',
      value: RecountOption.BuildCron
    },
    {
      label: 'jobScheduler.once',
      description: 'jobScheduler.repeatOnceDescription',
      value: RecountOption.Once
    },
    { label: 'jobScheduler.doNotRecount', value: RecountOption.DoNotRecount }
  ]
};

@Component({
  selector: 'knk-job-scheduler',
  templateUrl: 'scheduler.component.html',
  styleUrls: ['scheduler.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService]
})
export class JobSchedulerComponent implements OnInit {
  private readonly translate = inject(TranslateService);

  protected selectedRecountOption: RecountOption = RecountOption.PlainCron;

  protected specificTime: [TuiDay, TuiTime | null] = [
    TuiDay.currentLocal(),
    null
  ];

  get recountOptions() {
    return LOCALIZED_OPTIONS[this.section] || LOCALIZED_OPTIONS['default'];
  }

  @Input() showDoNotRecount = true;

  @Input() onlyPlain = false;

  @Input() cron: string | null = null;

  @Input() showPlainCronDescription = true;

  @Input() section: 'segments' | 'communications' | 'jobs' | 'default' =
    'default';

  @Output() cronChanged = new EventEmitter<string | null>();

  RecountOption = RecountOption;

  cronRegex = new RegExp(
    /^\s*($|#|\w+\s*=|(\?|\*|(?:[0-5]?\d)(?:(?:-|\/|,)(?:[0-5]?\d))?(?:,(?:[0-5]?\d)(?:(?:-|\/|,)(?:[0-5]?\d))?)*)\s+(\?|\*|(?:[0-5]?\d)(?:(?:-|\/|,)(?:[0-5]?\d))?(?:,(?:[0-5]?\d)(?:(?:-|\/|,)(?:[0-5]?\d))?)*)\s+(\?|\*|(?:[01]?\d|2[0-3])(?:(?:-|\/|,)(?:[01]?\d|2[0-3]))?(?:,(?:[01]?\d|2[0-3])(?:(?:-|\/|,)(?:[01]?\d|2[0-3]))?)*)\s+(\?|\*|(?:0?[1-9]|[12]\d|3[01])(?:(?:-|\/|,)(?:0?[1-9]|[12]\d|3[01]))?(?:,(?:0?[1-9]|[12]\d|3[01])(?:(?:-|\/|,)(?:0?[1-9]|[12]\d|3[01]))?)*)\s+(\?|\*|(?:[1-9]|1[012])(?:(?:-|\/|,)(?:[1-9]|1[012]))?(?:L|W)?(?:,(?:[1-9]|1[012])(?:(?:-|\/|,)(?:[1-9]|1[012]))?(?:L|W)?)*|\?|\*|(?:JAN|FEB|MAR|APR|MAY|JUN|JUL|AUG|SEP|OCT|NOV|DEC)(?:(?:-)(?:JAN|FEB|MAR|APR|MAY|JUN|JUL|AUG|SEP|OCT|NOV|DEC))?(?:,(?:JAN|FEB|MAR|APR|MAY|JUN|JUL|AUG|SEP|OCT|NOV|DEC)(?:(?:-)(?:JAN|FEB|MAR|APR|MAY|JUN|JUL|AUG|SEP|OCT|NOV|DEC))?)*)\s+(\?|\*|(?:[0-6])(?:(?:-|\/|,|#)(?:[0-6]))?(?:L)?(?:,(?:[0-6])(?:(?:-|\/|,|#)(?:[0-6]))?(?:L)?)*|\?|\*|(?:MON|TUE|WED|THU|FRI|SAT|SUN)(?:(?:-)(?:MON|TUE|WED|THU|FRI|SAT|SUN))?(?:,(?:MON|TUE|WED|THU|FRI|SAT|SUN)(?:(?:-)(?:MON|TUE|WED|THU|FRI|SAT|SUN))?)*)(|\s)+(\?|\*|(?:|\d{4})(?:(?:-|\/|,)(?:|\d{4}))?(?:,(?:|\d{4})(?:(?:-|\/|,)(?:|\d{4}))?)*))$/
  );

  cronControl = new FormControl<string | null>(
    null,
    Validators.pattern(this.cronRegex)
  );

  get filteredOptions() {
    let filtered = [...this.recountOptions];
    if (!this.showDoNotRecount) {
      filtered = filtered.filter((o) => o.value !== RecountOption.DoNotRecount);
    }
    return filtered;
  }

  constructor(private readonly destroy$: DestroyService) {}

  ngOnInit() {
    this.cronControl.setValue(this.cron);
    this.cronControl.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.cronChanged.emit(this.cronControl.value);
      });
  }

  getCronDescription() {
    return this.cronControl.value
      ? new Cron().parseCron(this.cronControl.value).describe(this.translate)
      : '';
  }

  changeSelectedRecount(selectedRecountOption: RecountOption): void {
    this.cronControl.setValue(null);
    this.selectedRecountOption = selectedRecountOption;
  }

  updateCron(cron: string): void {
    this.cronControl.setValue(cron);
    this.cronChanged.emit(this.cronControl.value);
  }

  updateCronFromCronBuilder(cron: Cron) {
    this.cronControl.setValue(cron.expression());
    this.cronChanged.emit(this.cronControl.value);
  }

  updateCronFromDate(event: [TuiDay, TuiTime]): void {
    const date = event[0].toLocalNativeDate();
    date.setHours(event[1].hours);
    date.setMinutes(event[1].minutes);
    this.cronControl.setValue(new Cron().parseDate(date, true).expression());
    this.cronChanged.emit(this.cronControl.value);
  }
}
