import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { API_URL } from '@konnektu/tokens';
import { Observable, map } from 'rxjs';
import { CalendarItemResponse, ScheduleItem } from './models';

export interface CreateSegmentExportDto {
  segmentId: number;
  segmentVersion?: number;
  instanceCabinetId: number;
  aiData?: {
    groupName: string;
    clusterName: string;
    lookALike?: { percent: number };
    sharing?: { platformId: number; customerId: string; cost: number };
  };
  yandexDmp?: object;
}

interface CreateScheduledSegmentExportDto extends CreateSegmentExportDto {
  cron: string;
}

@Injectable({ providedIn: 'root' })
export class JobsService {
  private readonly http = inject(HttpClient);

  private readonly apiUrl = inject(API_URL);

  private readonly scheduleUrl = `${this.apiUrl}/jobs/schedule`;

  private readonly triggerUrl = `${this.apiUrl}/jobs/trigger`;

  private readonly calendarUrl = `${this.apiUrl}/jobs/calendar`;

  recountSegment(segmentId: number): Observable<string> {
    return this.http.get(`${this.triggerUrl}/segment-recount/${segmentId}`, {
      responseType: 'text'
    });
  }

  deleteSchedule(scheduleId: string): Observable<boolean> {
    return this.http
      .delete(`${this.scheduleUrl}/${scheduleId}`, { observe: 'response' })
      .pipe(map((resp) => resp.ok));
  }

  scheduleSegmentRecount(segmentId: number, cron: string): Observable<string> {
    return this.http.post(
      `${this.scheduleUrl}/segment-recount/${segmentId}`,
      {
        cron
      },
      { responseType: 'text' }
    );
  }

  requestAllScheduled(type?: string): Observable<ScheduleItem[]> {
    return this.http
      .get<ScheduleItem[]>(this.scheduleUrl)
      .pipe(
        map((scheduled) =>
          type ? scheduled.filter((i) => i.job.type === type) : scheduled
        )
      );
  }

  getCalendar(
    start: Date,
    end: Date,
    additionalFilter?: HttpParams
  ): Observable<CalendarItemResponse[]> {
    return this.http.get<CalendarItemResponse[]>(
      `${
        this.calendarUrl
      }?start=${start.toISOString()}&end=${end.toISOString()}${
        additionalFilter ? '&' + additionalFilter.toString() : '&job.type=jobs*'
      }`
    );
  }

  publishPixel(pixelId: number): Observable<string> {
    return this.http.get(`${this.triggerUrl}/publish-pixel/${pixelId}`, {
      responseType: 'text'
    });
  }

  publishAllPixels(): Observable<string> {
    return this.http.get(`${this.triggerUrl}/publish-pixel`, {
      responseType: 'text'
    });
  }

  sendCommunication(
    communicationId: number,
    isTest: boolean,
    userIdentifierIds?: number[],
    segmentId?: number
  ): Observable<string> {
    return this.http.post(
      `${this.triggerUrl}/send-communication/${communicationId}`,
      {
        isTest,
        segmentId,
        recipients: userIdentifierIds?.length
          ? userIdentifierIds.map((identifierId) => ({
              id: identifierId
            }))
          : null
      },
      { responseType: 'text' }
    );
  }

  scheduleSendCommunication(
    communicationId: number,
    isTest: boolean,
    cron: string,
    userIdentifierIds?: number[],
    segmentId?: number
  ): Observable<string> {
    return this.http.post(
      `${this.scheduleUrl}/send-communication/${communicationId}`,
      {
        isTest,
        ...(segmentId ? { segmentId } : {}),
        ...(userIdentifierIds?.length
          ? {
              recipients: userIdentifierIds.map((identifierId) => ({
                id: identifierId
              }))
            }
          : {}),
        cron
      },
      { responseType: 'text' }
    );
  }

  sendABTestCommunicationChildren(
    communicationId: number,
    segmentId: number
  ): Observable<string> {
    return this.http.post(
      `${this.triggerUrl}/send-communication/${communicationId}`,
      {
        isTest: false,
        segmentId,
        recipients: null,
        abMode: 2
      },
      { responseType: 'text' }
    );
  }

  sendABTestCommunicationBase(
    communicationId: number,
    segmentId: number
  ): Observable<string> {
    return this.http.post(
      `${this.triggerUrl}/send-communication/${communicationId}`,
      {
        isTest: false,
        segmentId,
        recipients: null,
        abMode: 1
      },
      { responseType: 'text' }
    );
  }

  scheduleSendABTestCommunicationChildren(
    communicationId: number,
    segmentId: number,
    cron: string
  ): Observable<string> {
    return this.http.post(
      `${this.scheduleUrl}/send-communication/${communicationId}`,
      {
        isTest: false,
        segmentId,
        recipients: null,
        abMode: 2,
        cron
      },
      { responseType: 'text' }
    );
  }

  scheduleSendABTestCommunicationBase(
    communicationId: number,
    segmentId: number,
    cron: string
  ): Observable<string> {
    return this.http.post(
      `${this.scheduleUrl}/send-communication/${communicationId}`,
      {
        isTest: false,
        segmentId,
        recipients: null,
        abMode: 1,
        cron
      },
      { responseType: 'text' }
    );
  }

  /**
   * @deprecated
   */
  exportSegment(
    segmentId: number,
    instanceCabinetId: number
  ): Observable<string> {
    return this.http.post(
      `${this.apiUrl}/jobs/trigger/export-segment`,
      {
        segmentId,
        instanceCabinetId
      },
      {
        responseType: 'text'
      }
    );
  }

  /**
   * Uses new export method for segments exporter
   */
  createSegmentExport(dto: CreateSegmentExportDto) {
    return this.http.post(`${this.apiUrl}/exports`, dto, {
      responseType: 'text'
    });
  }

  /**
   * Uses new export method for segments exporter
   */
  createScheduledSegmentExport(dto: CreateScheduledSegmentExportDto) {
    return this.http.post(`${this.apiUrl}/exports/schedules`, dto, {
      responseType: 'text'
    });
  }

  /**
   * @deprecated
   */
  scheduleSegmentExport(
    segmentId: number,
    cabinetId: number,
    cron: string
  ): Observable<string> {
    return this.http.post(
      `${this.apiUrl}/jobs/schedule/export-segment`,
      {
        segmentId,
        InstanceCabinetId: cabinetId,
        cron
      },
      {
        responseType: 'text'
      }
    );
  }
}
