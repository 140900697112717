import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  DatepickerModule,
  DropdownModule,
  RadioGroupModule,
  TextInputModule
} from '@konnektu/components';
import { TranslatePipe } from '@konnektu/helpers';
import { TranslateModule } from '@ngx-translate/core';
import { TuiActiveZoneModule } from '@taiga-ui/cdk';
import {
  TuiCalendarModule,
  TuiDataListModule,
  TuiHostedDropdownModule,
  TuiLinkModule,
  TuiNotificationModule,
  TuiSvgModule,
  TuiTextfieldControllerModule
} from '@taiga-ui/core';
import {
  TuiInputDateTimeModule,
  TuiInputModule,
  TuiInputTimeModule,
  TuiRadioLabeledModule
} from '@taiga-ui/kit';
import { CronBuilderComponent, JobSchedulerComponent } from './components';

@NgModule({
  declarations: [JobSchedulerComponent, CronBuilderComponent],
  imports: [
    CommonModule,
    FormsModule,
    TextInputModule,
    DatepickerModule,
    RadioGroupModule,
    DropdownModule,
    TranslatePipe,
    TuiRadioLabeledModule,
    TuiInputModule,
    TuiActiveZoneModule,
    TuiTextfieldControllerModule,
    TuiInputDateTimeModule,
    TuiHostedDropdownModule,
    TuiDataListModule,
    TuiSvgModule,
    TuiCalendarModule,
    TuiLinkModule,
    TuiInputTimeModule,
    TuiNotificationModule,
    ReactiveFormsModule,
    TranslateModule
  ],
  exports: [JobSchedulerComponent, CronBuilderComponent]
})
export class JobsModule {}
